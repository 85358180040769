<template>
  <div style="height: 100%">
    <SourceTraceAccount />
  </div>
</template>

<script>
import { SourceTraceAccount } from '@/components/console/Index.vue';

export default {
  components: {
    SourceTraceAccount,
  },
};
</script>
